import { clearHtmlTags } from "@/utils";
import { useCallback, useEffect } from "react";
import toast from "react-hot-toast";
import useCart from "../cart/hook/useCart";
import { updateTradeInInformation } from "../cart/lib/action";
import { useCartStore } from "../cart/store/useCartStore";
import useTradeInStore from "./hooks/useTradeInStore";
import { TradeInOrder } from "./libs/type";
import ProductDiagnosticForm, { ProductDiagnosticFormState } from "./ProductDiagnosticForm";
import SelectDeliverForm, { SelectDeliverFormState } from "./SelectDeliverForm";
import SelectDeviceForm, { SelectDeviceFormState } from "./SelectDeviceForm";
export type FormState = ProductDiagnosticFormState | SelectDeliverFormState | SelectDeviceFormState;
export interface FormPartProps<T> {
  onPrev?: () => void;
  onSubmit: (values: T) => Promise<void>;
}
export function TradeInForm() {
  const {
    action,
    cartKey,
    closeModal,
    diagnosticQuoted,
    formValues,
    reset,
    selectedBrand,
    selectedCategory,
    selectedModel,
    selectedVariant,
    setDone,
    setFormValues,
    setStep,
    setTradeInOrder,
    step,
    tradeInOrder,
    tradeUpId,
    voucher
  } = useTradeInStore();
  const {
    getCart
  } = useCart();
  const {
    setCart
  } = useCartStore(state => state);
  const handleOnNext = useCallback(async () => {
    setStep(step + 1);
  }, [step, setStep]);
  const handleOnPrev = useCallback(async () => {
    setStep(step - 1);
  }, [step, setStep]);
  const handleOnSubmit = useCallback(async (values: SelectDeliverFormState) => {
    const order: TradeInOrder = {
      ...values,
      bankId: Number(values.bankId),
      deviceDiagnosticUuid: diagnosticQuoted?.deviceDiagnosticUuid ?? "",
      dropoffStoreId: values.dropoffStoreId ? Number(values.dropoffStoreId) : null,
      pickupDropoffType: values.pickupDropoffType!,
      postcode: values.postcode ? values.postcode : null,
      tradeUpId: tradeUpId ?? null,
      voucherId: voucher?.voucherId ?? null
    };
    setTradeInOrder(order);
    setFormValues({
      ...formValues,
      selectDeliver: values
    });
    setDone(true);
    if (action === "update" && cartKey) {
      await updateTradeInInformation({
        cartKey,
        tradeInInformation: JSON.stringify({
          diagnosticQuoted,
          formValues: {
            ...formValues,
            selectDeliver: values
          },
          selectedBrand,
          selectedCategory,
          selectedModel,
          selectedVariant,
          tradeInOrder,
          tradeUpId,
          voucher
        })
      }).then(async response => {
        if (!response.success) {
          toast.error(clearHtmlTags(response.error));
        }
        if (response.success) {
          await getCart().then(response => response?.success && setCart(response.data));
          toast.success("Updated successfully.");
          reset();
        }
      });
    } else {
      toast.success("Thank you for your joining trade in program.");
    }
    closeModal();
  }, [action, cartKey, closeModal, diagnosticQuoted, formValues, getCart, reset, selectedBrand, selectedCategory, selectedModel, selectedVariant, setCart, setDone, setFormValues, setTradeInOrder, tradeInOrder, tradeUpId, voucher]);
  return <div data-sentry-component="TradeInForm" data-sentry-source-file="TradeInForm.tsx">
      {step === 0 && <SelectDeviceForm onSubmit={handleOnNext} />}
      {step === 1 && <ProductDiagnosticForm onPrev={handleOnPrev} onSubmit={handleOnNext} />}
      {step === 2 && <SelectDeliverForm onPrev={handleOnPrev} onSubmit={handleOnSubmit} />}
    </div>;
}
export default TradeInForm;