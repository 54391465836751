export const endpoints = {
  AUTHENTICATION: {
    REFRESH: "/wp-json/jwt-auth/v1/token/refresh",
    TOKEN: "/wp-json/uficon/v1/token",
    VALIDATE: "/wp-json/jwt-auth/v1/token/validate",
  },
  CART: "/api/cart",
  CONFIG: "/wp-json/uficon/v1/configuration",
  COUPON: "/api/coupon",
  PRODUCTS: "/api/products",
  SESSION: "/api/session",
};
