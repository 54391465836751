"use client";

import { useCartStore } from "@/features/cart/store/useCartStore";
import useSession from "@/hooks/useSession";
import { LockOutlined, PoweroffOutlined, ShoppingOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps, Modal } from "antd";
import { useRouter } from "next/navigation";
import { useTranslation } from "next-i18next";
function UserDropdownMenu() {
  const {
    t
  } = useTranslation();
  const router = useRouter();
  const {
    logout,
    session
  } = useSession();
  const {
    reset
  } = useCartStore(state => state);
  const [modal, contextHolder] = Modal.useModal();
  let items: MenuProps["items"] = [{
    icon: <LockOutlined />,
    key: "1",
    label: t("header.menu.login"),
    onClick: () => router.push("/login")
  }, {
    icon: <UserOutlined />,
    key: "2",
    label: t("header.menu.register"),
    onClick: () => router.push("/register")
  }];
  if (session?.isLoggedIn) {
    items = [{
      icon: <UserOutlined />,
      key: "1",
      label: t("header.menu.profile"),
      onClick: () => router.push("/account/profile")
    }, {
      icon: <ShoppingOutlined />,
      key: "2",
      label: t("header.menu.orderHistory"),
      onClick: () => router.push("/account/order-history")
    }, {
      type: "divider"
    }, {
      icon: <PoweroffOutlined />,
      key: "3",
      label: t("header.menu.logout"),
      onClick: () => {
        confirm();
      }
    }];
  }
  const confirm = () => {
    modal.confirm({
      cancelText: t("cancel"),
      content: t("logout.description"),
      icon: <PoweroffOutlined />,
      okText: t("logout.title"),
      onOk: async () => {
        await logout().then(() => {
          reset();
          window.location.replace("/login");
        });
      },
      title: t("logout.confirm")
    });
  };
  return <>
      <Dropdown menu={{
      items
    }} placement="bottomLeft" data-sentry-element="Dropdown" data-sentry-source-file="UserDropdownMenu.tsx">
        <Button className="flex h-10 w-10 items-center justify-center rounded-full border-none bg-transparent text-xl text-white shadow-none" data-sentry-element="Button" data-sentry-source-file="UserDropdownMenu.tsx">
          <UserOutlined data-sentry-element="UserOutlined" data-sentry-source-file="UserDropdownMenu.tsx" />
        </Button>
      </Dropdown>
      {contextHolder}
    </>;
}
export default UserDropdownMenu;