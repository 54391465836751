interface QuestionTitleProps {
  description?: string;
  title: string;
}
export function QuestionTitle({
  description,
  title
}: QuestionTitleProps) {
  return <div data-sentry-component="QuestionTitle" data-sentry-source-file="QuestionTitle.tsx">
      <h4 className="text-lg font-bold leading-tight md:text-2xl">{title}</h4>
      {description && <p className="mt-2 leading-tight text-gray-500">{description}</p>}
    </div>;
}
export default QuestionTitle;