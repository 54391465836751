import {
  AddItemToCartMutationVariables,
  AddToCartPayload,
  Cart,
  RemoveItemsFromCartMutationVariables,
  RemoveItemsFromCartPayload,
} from "@/__generated__/graphql";
import { fetcher, handleDelete, handleRequest } from "@/lib/fetcher";
import { endpoints } from "@/services/endpoints";
import { Response } from "@/types/common";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";

export default function useCart(key?: string) {
  const {
    data,
    error,
    isLoading: isGetCartLoading,
    mutate: getCart,
  } = useSWR<Response<Cart>>(endpoints.CART, fetcher);

  const { isMutating: isAddToCartLoading, trigger: addToCart } = useSWRMutation(
    endpoints.CART,
    handleRequest<AddItemToCartMutationVariables, Response<AddToCartPayload>>,
    {
      revalidate: false,
    },
  );

  const { isMutating: isRemoveFromCartLoading, trigger: removeFromCart } =
    useSWRMutation(
      `${endpoints.CART}/${key}`,
      handleDelete<
        RemoveItemsFromCartMutationVariables,
        Response<RemoveItemsFromCartPayload>
      >,
      {
        revalidate: false,
      },
    );

  const isLoading =
    isGetCartLoading || isAddToCartLoading || isRemoveFromCartLoading;
  const items = data?.success ? data.data.contents?.nodes : null;
  const itemCount = data?.success ? data.data.contents?.itemCount : 0;

  const cart = data?.success && data.data;

  return {
    addToCart,
    cart: cart as Cart,
    data,
    error,
    getCart,
    isLoading,
    itemCount,
    items,
    removeFromCart,
  };
}
