"use client";

import { AppProgressBar as ProgressBar } from "next-nprogress-bar";
const ProgressBarProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  return <>
      {children}
      <ProgressBar color="#5491f5" height="4px" options={{
      showSpinner: true
    }} shallowRouting data-sentry-element="ProgressBar" data-sentry-source-file="ProgressBarProvider.tsx" />
    </>;
};
export default ProgressBarProvider;