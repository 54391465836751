"use client";

import { FloatButton, Space, Typography } from "antd";
import { useTranslation } from "next-i18next";
const {
  Paragraph,
  Text
} = Typography;
export default function Footer() {
  const {
    t
  } = useTranslation(["footer"]);
  return <footer className="bg-[#ebebeb] py-6" data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
      <div className="container text-center">
        <Space direction="vertical" size="small" data-sentry-element="Space" data-sentry-source-file="Footer.tsx">
          <Paragraph style={{
          fontSize: "0.9em"
        }} data-sentry-element="Paragraph" data-sentry-source-file="Footer.tsx">
            {t("address")} <br />
            {t("contact")}
          </Paragraph>
          <Text style={{
          fontSize: "0.9em"
        }} type="secondary" data-sentry-element="Text" data-sentry-source-file="Footer.tsx">
            {t("copyRight")}
          </Text>
        </Space>
      </div>
      <FloatButton.BackTop data-sentry-element="unknown" data-sentry-source-file="Footer.tsx" />
    </footer>;
}