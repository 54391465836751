import useSWR, { SWRConfiguration } from "swr";

import { endpoints } from "../libs/constant";
import { fetcher } from "../libs/fetcher";
import {
  GetBankListResponse,
  GetDiagnosticResponse,
  GetDropOffLocationsResponse,
  GetModelsResponse,
  GetProductBrandsResponse,
  GetProductCategoryResponse,
  GetProductsResponse,
  GetVariantsResponse,
} from "../libs/type";

const config: SWRConfiguration = {
  refreshInterval: 0,
  refreshWhenHidden: false,
  refreshWhenOffline: false,
  revalidateIfStale: false,
  revalidateOnFocus: false,
  // revalidateOnMount: false,
  revalidateOnReconnect: false,
};

export function useTradeIn() {
  const useGetProductCategories = () => {
    return useSWR<GetProductCategoryResponse>(
      endpoints.getProductCategories,
      fetcher,
      config,
    );
  };

  const useGetProductBrands = (productCategoryId: string) => {
    return useSWR<GetProductBrandsResponse>(
      `${endpoints.getProductBrands}/?productCategoryId=${productCategoryId}`,
      fetcher,
      config,
    );
  };

  const useGetProductModels = (productGroupId: string) => {
    return useSWR<GetModelsResponse>(
      `${endpoints.getProductModels}/?productGroupId=${productGroupId}`,
      fetcher,
      config,
    );
  };

  const useGetProductVariants = (productId: string) => {
    return useSWR<GetVariantsResponse>(
      `${endpoints.getProductVariants}/?productId=${productId}`,
      fetcher,
      config,
    );
  };

  const useGetDiagnosticQuestions = (productVariantId: string) => {
    return useSWR<GetDiagnosticResponse>(
      `${endpoints.getDiagnosticQuestions}/?productVariantId=${productVariantId}`,
      fetcher,
      config,
    );
  };

  const useGetDropoffLocations = () => {
    return useSWR<GetDropOffLocationsResponse>(
      endpoints.getDropOffLocations,
      fetcher,
      config,
    );
  };

  const useGetBankList = () => {
    return useSWR<GetBankListResponse>(endpoints.getBankList, fetcher, config);
  };

  const useGetProducts = () => {
    return useSWR<GetProductsResponse>(endpoints.getProducts, fetcher, config);
  };

  return {
    getBankList: useGetBankList,
    getDiagnosticQuestions: useGetDiagnosticQuestions,
    getDropoffLocations: useGetDropoffLocations,
    getProductBrands: useGetProductBrands,
    getProductCategories: useGetProductCategories,
    getProductModels: useGetProductModels,
    getProducts: useGetProducts,
    getProductVariants: useGetProductVariants,
  };
}
