import(/* webpackMode: "eager", webpackExports: ["ConfigProvider"] */ "__barrel_optimize__?names=ConfigProvider!=!/app/node_modules/antd/es/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/app/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../../public/fonts/SukhumvitSet-Thin.ttf\",\"weight\":\"100\"},{\"path\":\"../../../public/fonts/SukhumvitSet-Light.ttf\",\"weight\":\"300\"},{\"path\":\"../../../public/fonts/SukhumvitSet-Regular.ttf\",\"weight\":\"400\"},{\"path\":\"../../../public/fonts/SukhumvitSet-Medium.ttf\",\"weight\":\"500\"},{\"path\":\"../../../public/fonts/SukhumvitSet-SemiBold.ttf\",\"weight\":\"600\"},{\"path\":\"../../../public/fonts/SukhumvitSet-Bold.ttf\",\"weight\":\"700\"}]}],\"variableName\":\"sukhumvit\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/node_modules/react-hot-toast/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/AlertTemporaryClosed.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CampaignStartDate"] */ "/app/src/components/CampaignStartDate.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/CountDown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/NavigationMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/provider/ProgressBarProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/provider/TranslationsProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Sourcebuster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/TopBannerSlider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/UserDropdownMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/cart/CartButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/language-switcher/LanguageSwitcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/features/trade-in/TradeInModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/layout/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/lib/configuration/Configuration.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/global.scss");
