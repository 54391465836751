"use client";

import { useEffect } from "react";
import { useConfiguration } from "./hooks/useConfiguration";
import useConfigurationStore from "./hooks/useConfigurationStore";
export function Configuration() {
  const {
    setConfiguration
  } = useConfigurationStore();
  const {
    data
  } = useConfiguration();
  useEffect(() => {
    if (data) {
      setConfiguration(data);
    }
  }, [data, setConfiguration]);
  return <></>;
}
export default Configuration;