"use client";

import { ShoppingCartOutlined } from "@ant-design/icons";
import { Badge, Button } from "antd";
import Link from "next/link";
import { useEffect } from "react";
import useCart from "./hook/useCart";
import { useCartStore } from "./store/useCartStore";
function CartButton() {
  const {
    getCart
  } = useCart();
  const {
    itemCount,
    setCart
  } = useCartStore(state => state);
  useEffect(() => {
    async function fetch() {
      const response = await getCart();
      if (response?.success) {
        setCart(response.data);
      }
    }
    fetch();
  }, [getCart, setCart]);
  return <Badge className="[&>sup]:text-black" color="#fff" count={itemCount} data-sentry-element="Badge" data-sentry-component="CartButton" data-sentry-source-file="CartButton.tsx">
      <Link href="/cart" prefetch data-sentry-element="Link" data-sentry-source-file="CartButton.tsx">
        <Button className="flex h-10 w-10 items-center justify-center rounded-full border-none bg-transparent text-xl text-white shadow-none" data-sentry-element="Button" data-sentry-source-file="CartButton.tsx">
          <ShoppingCartOutlined data-sentry-element="ShoppingCartOutlined" data-sentry-source-file="CartButton.tsx" />
        </Button>
      </Link>
    </Badge>;
}
export default CartButton;