/**
 * Renders a localized string based on the specified locale.
 * If the locale is "th", the Thai string is returned.
 * Otherwise, the English string is returned.
 *
 * @param thString - The Thai string.
 * @param enString - The English string.
 * @param locale - The locale to determine which string to return.
 * @returns The localized string based on the specified locale.
 */
export function renderString(
  thString: null | string | undefined,
  enString: null | string | undefined,
  locale: string,
) {
  return locale === "th" ? thString : enString;
}
