"use client";

import initTranslations from "@/app/i18n";
import { createInstance, Resource } from "i18next";
import { ReactNode } from "react";
import { I18nextProvider } from "react-i18next";
export interface TranslationsProviderProps {
  children: ReactNode;
  locale: string;
  namespaces: string[];
  resources: Resource;
}
export default function TranslationsProvider({
  children,
  locale,
  namespaces,
  resources
}: Readonly<TranslationsProviderProps>) {
  const i18n = createInstance();
  initTranslations(locale, namespaces, i18n, resources);
  return <I18nextProvider i18n={i18n} data-sentry-element="I18nextProvider" data-sentry-component="TranslationsProvider" data-sentry-source-file="TranslationsProvider.tsx">{children}</I18nextProvider>;
}