// UAT
export const ENDPOINT_URI = "https://b2bapish-uat.compasia.com";
export const API_KEY = "ae717f71-b572-44c5-9b94-5113a276c453";

// Production
// export const ENDPOINT_URI = "https://b2bapi-partner.compasia.com";
// export const API_KEY = "e8c91ec2-b9fc-4980-a38f-24c722aa3ec2";

export const endpoints = {
  createOrder: `${ENDPOINT_URI}/api/v1/smart-exchange/integration-c2b-b2b-microsite/order`,
  getBankList: `${ENDPOINT_URI}/api/v1/smart-exchange/integration-c2b-b2b-microsite/bank/bank-list`,
  getDiagnosticQuestions: `${ENDPOINT_URI}/api/v1/smart-exchange/integration-c2b-b2b-microsite/diagnostic/diagnostic-list`,
  getDiagnosticQuotedPrice: `${ENDPOINT_URI}/api/v1/smart-exchange/integration-c2b-b2b-microsite/diagnostic/device-diagnostic-result`,
  getDropOffLocations: `${ENDPOINT_URI}/api/v1/smart-exchange/integration-c2b-b2b-microsite/dropoff/dropoff-store`,
  getEligibleVoucher: `${ENDPOINT_URI}/api/v1/smart-exchange/integration-c2b-b2b-microsite/voucher/eligible-voucher`,
  getProductBrands: `${ENDPOINT_URI}/api/v1/smart-exchange/integration-c2b-b2b-microsite/product/trade-in/product-group`,
  getProductCategories: `${ENDPOINT_URI}/api/v1/smart-exchange/integration-c2b-b2b-microsite/product/trade-in/product-category`,
  getProductModels: `${ENDPOINT_URI}/api/v1/smart-exchange/integration-c2b-b2b-microsite/product/trade-in/product-model`,
  getProducts: `${ENDPOINT_URI}/api/v1/smart-exchange/integration-c2b-b2b-microsite/product/trade-up/product-list`,
  getProductVariants: `${ENDPOINT_URI}/api/v1/smart-exchange/integration-c2b-b2b-microsite/product/trade-in/product-variant`,
  validateIMEI: `${ENDPOINT_URI}/api/v1/smart-exchange/integration-c2b-b2b-microsite/product/trade-in/imei`,
  validatePostcode: `${ENDPOINT_URI}/api/v1/smart-exchange/integration-c2b-b2b-microsite/region/postcode`,
};
