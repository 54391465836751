import { cn } from "@/utils";
import React from "react";
import { useTranslation } from "react-i18next";
import { useCountdown } from "./lib/useCountdown";
export interface ShowCounterProps {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}
const Separator = () => <span className="-mt-6 block px-1 text-4xl md:px-2" data-sentry-component="Separator" data-sentry-source-file="CountdownTimer.tsx">:</span>;
export const ShowCounter = ({
  days,
  hours,
  minutes,
  seconds
}: ShowCounterProps) => {
  return <div className="flex items-center justify-center" data-sentry-component="ShowCounter" data-sentry-source-file="CountdownTimer.tsx">
      <DateTimeDisplay type={"days"} value={days} data-sentry-element="DateTimeDisplay" data-sentry-source-file="CountdownTimer.tsx" />
      <Separator data-sentry-element="Separator" data-sentry-source-file="CountdownTimer.tsx" />
      <DateTimeDisplay type={"hours"} value={hours} data-sentry-element="DateTimeDisplay" data-sentry-source-file="CountdownTimer.tsx" />
      <Separator data-sentry-element="Separator" data-sentry-source-file="CountdownTimer.tsx" />
      <DateTimeDisplay type={"minutes"} value={minutes} data-sentry-element="DateTimeDisplay" data-sentry-source-file="CountdownTimer.tsx" />
      <Separator data-sentry-element="Separator" data-sentry-source-file="CountdownTimer.tsx" />
      <DateTimeDisplay type={"seconds"} value={seconds} data-sentry-element="DateTimeDisplay" data-sentry-source-file="CountdownTimer.tsx" />
    </div>;
};
export const CountdownTimer = ({
  targetDate
}: {
  targetDate: Date;
}) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  if (days + hours + minutes + seconds <= 0) {
    return <></>;
  }
  return <ShowCounter days={days} hours={hours} minutes={minutes} seconds={seconds} data-sentry-element="ShowCounter" data-sentry-component="CountdownTimer" data-sentry-source-file="CountdownTimer.tsx" />;
};
export default CountdownTimer;
export interface DateTimeDisplayProps {
  type: "days" | "hours" | "minutes" | "seconds";
  value: number;
}
export const DateTimeDisplay = ({
  type,
  value
}: DateTimeDisplayProps) => {
  const {
    t
  } = useTranslation();
  const formattedValue = value.toString().padStart(2, "0");
  const digits = formattedValue.toString().split("");
  return <div className={cn("text-center")} data-sentry-component="DateTimeDisplay" data-sentry-source-file="CountdownTimer.tsx">
      <p className="flex space-x-1">
        {digits.map((digit, index) => <span className={cn("block rounded-md bg-black p-2 text-2xl font-semibold leading-none text-white md:p-3 md:text-3xl")} key={index}>
            {digit}
          </span>)}
      </p>
      <span className="mt-2 block font-semibold uppercase">
        {t(`countDown.${type}`)}
      </span>
    </div>;
};