"use client";

import { useConfigurationStore } from "@/lib/configuration/hooks/useConfigurationStore";
import Image from "next/image";
import Link from "next/link";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
function TopBannerSlider() {
  const {
    topBanners
  } = useConfigurationStore();
  if (topBanners?.length === 0) return <></>;
  return <Swiper autoplay={{
    delay: 5000,
    disableOnInteraction: false
  }} className="aspect-[6/1] md:aspect-[445/19]" lazyPreloadPrevNext={1} loop={true} modules={[Autoplay]} slidesPerView={1} data-sentry-element="Swiper" data-sentry-component="TopBannerSlider" data-sentry-source-file="TopBannerSlider.tsx">
      {topBanners?.map((item, index) => <SwiperSlide key={index}>
          <Link href={item?.link ?? "#"} target="__blank">
            <Image alt="" className="hidden h-full w-full object-cover md:block" height={65} src={item?.desktopImage as string} width={1500} />
            <Image alt="" className="block h-full w-full object-cover md:hidden" height={128} src={item?.mobileImage as string} width={767} />
          </Link>
        </SwiperSlide>)}
    </Swiper>;
}
export default TopBannerSlider;