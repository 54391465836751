// import { UserConfig } from "next-i18next";

// const i18NextConfig: UserConfig = {
//   debug: process.env.NODE_ENV === "development",
//   i18n: {
//     locales: ["en", "th"],
//     defaultLocale: "th",
//     localeDetection: false,
//   },
//   fallbackLng: "th",
//   localePath:
//     typeof window === "undefined"
//       ? require("path").resolve("./public/locales")
//       : "/public/locales",
//   ns: ["common", "footer"],
//   reloadOnPrerender: process.env.NODE_ENV === "development",
// };

// export default i18NextConfig;

import { Config } from "next-i18n-router/dist/types";

const i18nConfig: Config = {
  defaultLocale: "th",
  localeDetector: (request, config) => {
    if (request.headers.get("accept-language")?.includes("en")) {
      return "en";
    }
    return "th";
  },
  locales: ["th", "en"],
};

export default i18nConfig;
