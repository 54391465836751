import { formatPrice } from "@/utils/format";
import Image from "next/image";
import { useTranslation } from "react-i18next";
import useTradeInStore from "../hooks/useTradeInStore";
export function ProductDetail() {
  const {
    t
  } = useTranslation();
  const {
    diagnosticQuoted,
    selectedCategory,
    selectedVariant,
    voucher
  } = useTradeInStore();
  return <div className="mb-8 flex items-center gap-4 rounded-xl bg-gray-50 p-4" data-sentry-component="ProductDetail" data-sentry-source-file="ProductDetail.tsx">
      <div className="flex-1">
        <span className="block text-base font-bold">
          {selectedVariant?.productFullName}
        </span>

        {diagnosticQuoted && diagnosticQuoted?.quotedPrice > 0 && <>
            <span className="text-xs leading-tight">
              {t("tradeIn.card.potentialValue", {
            amount: formatPrice(diagnosticQuoted?.quotedPrice || 0)
          })}
            </span>
            {voucher && voucher?.amount > 0 && <span className="text-xs leading-tight">
                {" "}
                |{" "}
                {t("tradeIn.card.congratulations", {
            amount: formatPrice(voucher?.amount)
          })}
              </span>}
            <span className="mt-3 block text-base font-bold leading-tight text-black">
              {t("tradeIn.card.totalValue", {
            amount: formatPrice(diagnosticQuoted?.quotedPrice + (voucher?.amount || 0))
          })}
            </span>
            <span className="block text-xs italic text-gray-600">
              ({t("tradeIn.card.terms")})
            </span>
          </>}
      </div>
      <div className="w-28 flex-none">
        <Image alt={selectedCategory?.productCategoryName || ""} className="mx-auto mb-2 aspect-square" height={80} src={selectedCategory?.productCategoryPrefix === "T" ? `/img/tablet-icon.png` : selectedCategory?.productCategoryPrefix === "L" ? `/img/laptop-icon.png` : selectedCategory?.productCategoryPrefix === "W" ? `/img/watch-icon.png` : `/img/smartphone-icon.png`} width={80} data-sentry-element="Image" data-sentry-source-file="ProductDetail.tsx" />
      </div>
    </div>;
}
export default ProductDetail;