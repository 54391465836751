import type { SessionOptions } from "iron-session";

import { User } from "@/features/authentication/lib/type";

export const sessionOptions: SessionOptions = {
  cookieName: "next-iron-session",
  cookieOptions: {
    secure: process.env.NODE_ENV === "production",
  },
  password: process.env.SECRET_COOKIE_PASSWORD as string,
};

export type AuthenticationState = {
  isLoggedIn: boolean;
} & User;

export const defaultSession: AuthenticationState = {
  authToken: "",
  displayName: "",
  email: "",
  firstName: "",
  isLoggedIn: false,
  lastName: "",
  phoneNumber: "",
  refreshToken: "",
};

declare module "iron-session" {
  type IronSessionData = AuthenticationState;
}
