import { Cart, CartItem } from "@/__generated__/graphql";
import { create } from "zustand";
import { persist } from "zustand/middleware";

type CartState = {
  itemCount: number;
  items: CartItem[];
} & Cart;

type CartActions = {
  reset: () => void;
  setCart: (cart: Cart) => void;
};

const initialState: CartState = {
  itemCount: 0,
  items: [],
};

export const useCartStore = create<CartActions & CartState>()(
  persist(
    (set) => ({
      ...initialState,
      reset: () => {
        set(initialState);
      },
      setCart: (cart) => {
        const items = cart?.contents?.nodes ?? [];
        set({
          ...cart,
          itemCount: items.length ?? 0,
          items,
        });
      },
    }),
    {
      name: "cartStorage",
    },
  ),
);
