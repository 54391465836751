"use client";

import { useConfigurationStore } from "@/lib/configuration/hooks/useConfigurationStore";
import { Alert } from "antd";
import Message from "./Message";
function AlertTemporarilyClosed() {
  const {
    alertMessage,
    alertMessageEn,
    temporarilyClosed
  } = useConfigurationStore();
  return <>
      {temporarilyClosed && <Alert banner message={<Message alertMessage={alertMessage} alertMessageEn={alertMessageEn} />} />}
    </>;
}
export default AlertTemporarilyClosed;