import { fetcher } from "@/lib/fetcher";
import { defaultSession } from "@/lib/session";
import { endpoints } from "@/services/endpoints";
import { IronSessionData } from "iron-session";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";

/**
 * Handles the login process by sending a POST request to the specified URL with the provided username and password.
 * @param url - The URL to send the login request to.
 * @param arg - An object containing the username and password.
 * @returns A Promise that resolves to the response data or rejects with an error.
 */
async function handleLogin(
  url: string,
  { arg }: { arg: { password: string; username: string } },
) {
  return await fetcher<IronSessionData>(url, {
    body: JSON.stringify({ ...arg }),
    method: "POST",
  }).catch((error) => {
    throw new Error(error.message);
  });
}

/**
 * Handles the logout functionality by making a DELETE request to the specified URL.
 * @param url - The URL to send the DELETE request to.
 * @returns A Promise that resolves to the response data.
 */
async function handleLogout(url: string): Promise<IronSessionData> {
  return fetcher<IronSessionData>(url, {
    method: "DELETE",
  });
}

/**
 * Custom hook for managing user session.
 * @returns An object containing session data, login and logout functions, and loading state.
 */
export default function useSession() {
  const { data: session, isLoading } = useSWR(
    endpoints.SESSION,
    fetcher<IronSessionData>,
    {
      fallbackData: defaultSession,
    },
  );

  const { trigger: logout } = useSWRMutation(endpoints.SESSION, handleLogout);

  return { isLoading, logout, session };
}
