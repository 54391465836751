import { cn } from "@/utils";
export interface StepperProps {
  count: number;
  step: number;
}
export function Stepper({
  count,
  step
}: StepperProps) {
  return <div className="grid grid-cols-3 gap-2" data-sentry-component="Stepper" data-sentry-source-file="Stepper.tsx">
      {Array.from({
      length: count
    }).map((_, index) => <StepperItem active={index === step} key={index} />)}
    </div>;
}
export default Stepper;
function StepperItem({
  active
}: {
  active: boolean;
}) {
  return <div className={cn("h-1 w-full rounded-lg bg-gray-200", {
    "bg-primary": active
  })} data-sentry-component="StepperItem" data-sentry-source-file="Stepper.tsx"></div>;
}