"use client";

import { useConfigurationStore } from "@/lib/configuration/hooks/useConfigurationStore";
import { Button } from "antd";
import { useRouter } from "next/navigation";
import { useTranslation } from "react-i18next";
function NavigationMenu() {
  const router = useRouter();
  const {
    t
  } = useTranslation();
  const {
    productSettingsForIpad,
    productSettingsForIphone,
    productSettingsForMac,
    productSettingsForWatch
  } = useConfigurationStore();
  return <div className="space-x-6" data-sentry-component="NavigationMenu" data-sentry-source-file="NavigationMenu.tsx">
      {productSettingsForMac?.enable && <Button className="text-white" onClick={() => router.push("/mac")} type="text">
          {t("productType.mac")}
        </Button>}

      {productSettingsForIpad?.enable && <Button className="text-white" onClick={() => router.push("/ipad")} type="text">
          {t("productType.ipad")}
        </Button>}

      {productSettingsForIphone?.enable && <Button className="text-white" onClick={() => router.push("/iphone")} type="text">
          {t("productType.iphone")}
        </Button>}

      {productSettingsForWatch?.enable && <Button className="text-white" onClick={() => router.push("/watch")} type="text">
          {t("productType.watch")}
        </Button>}
    </div>;
}
export default NavigationMenu;