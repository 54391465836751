"use client";

import { useEffect } from "react";
import sbjs from "sourcebuster";
declare global {
  interface Window {
    global: unknown;
  }
}
if (typeof window !== "undefined") {
  window.global = window;
}
export function Sourcebuster() {
  useEffect(() => {
    // check if have document available
    if (typeof document !== "undefined") {
      sbjs.init({
        lifetime: Number(0.00001),
        session_length: Number(30),
        timezone_offset: 0 // utc
      });
    }
  }, []);
  return <></>;
}
export default Sourcebuster;