"use client";

import i18nConfig from "@/i18nConfig";
import { Button, Col, Row } from "antd";
import { usePathname, useRouter } from "next/navigation";
import { useTranslation } from "react-i18next";
export function LanguageSwitcher() {
  const {
    i18n
  } = useTranslation();
  const currentLocale = i18n.language;
  const router = useRouter();
  const currentPathname = usePathname();
  function handleChange(locale: string) {
    const days = 30;
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `NEXT_LOCALE=${locale};expires=${date.toUTCString()};path=/`;
    if (currentLocale === i18nConfig.defaultLocale && !i18nConfig.prefixDefault) {
      router.push("/" + locale + currentPathname);
    } else {
      router.push(currentPathname.replace(`/${currentLocale}`, `/${locale}`));
    }
    router.refresh();
  }
  return <Row data-sentry-element="Row" data-sentry-component="LanguageSwitcher" data-sentry-source-file="LanguageSwitcher.tsx">
      <Col span={24} data-sentry-element="Col" data-sentry-source-file="LanguageSwitcher.tsx">
        <Button onClick={() => handleChange("th")} style={{
        fontWeight: 600,
        marginRight: "0.25rem"
      }} type={i18n.language === "th" ? "primary" : "default"} data-sentry-element="Button" data-sentry-source-file="LanguageSwitcher.tsx">
          ไทย
        </Button>
        <Button onClick={() => handleChange("en")} style={{
        fontWeight: 600
      }} type={i18n.language === "en" ? "primary" : "default"} data-sentry-element="Button" data-sentry-source-file="LanguageSwitcher.tsx">
          Eng
        </Button>
      </Col>
    </Row>;
}
export default LanguageSwitcher;