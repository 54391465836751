"use client";

import { renderString } from "@/utils/i18n";
import Marquee from "react-fast-marquee";
import { useTranslation } from "react-i18next";
function Message({
  alertMessage,
  alertMessageEn
}: Readonly<{
  alertMessage: string;
  alertMessageEn: string;
}>) {
  const {
    i18n
  } = useTranslation();
  return <Marquee gradient={false} pauseOnHover data-sentry-element="Marquee" data-sentry-component="Message" data-sentry-source-file="Message.tsx">
      {renderString(alertMessage, alertMessageEn, i18n.language)}
    </Marquee>;
}
export default Message;