import { headers } from "./service";

export async function fetcher<JSON = unknown>(
  input: RequestInfo,
  init?: RequestInit,
): Promise<JSON> {
  const res = await fetch(input, {
    headers,
    ...init,
  });

  // if (!res.ok) {
  //   const error = new Error("An error occurred while fetching the data.");
  //   // Attach extra info to the error object.
  //   // error.info = await res.json();
  //   // error.status = res.status;
  //   throw error;
  // }

  return res.json();
}
