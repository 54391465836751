"use client";

import CountdownTimer from "@/features/countdown-timer/CountdownTimer";
import { useConfigurationStore } from "@/lib/configuration/hooks/useConfigurationStore";
import { formatDate } from "@/utils/format";
import { Statistic } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
const {
  Countdown
} = Statistic;
function refresh() {
  window.location.reload();
}
function CountDown() {
  const {
    i18n,
    t
  } = useTranslation();
  const {
    campaignStartDate
  } = useConfigurationStore();
  if (campaignStartDate && dayjs().isBefore(campaignStartDate)) {
    // const text =
    //   t("general.campaignStart") +
    //   " " +
    //   formatDate(campaignStartDate, i18n.language === "th" ? "th-TH" : "en-US");
    return <>
        <div className="mb-14">
          <h4 className="mb-4 text-balance text-center text-3xl md:mb-6">
            {i18n.language === "th" && <>
                <span className="font-semibold">{t("countDownText")}</span>{" "}
                {t("countDownWithIn")}
              </>}
            {i18n.language === "en" && <>
                <span className="font-semibold">{t("countDownWithIn")}</span>{" "}
                {t("countDownText")}
              </>}
          </h4>
          <CountdownTimer targetDate={campaignStartDate} />
        </div>
        {/* <Countdown
          className="countdown-timer mb-10 text-center"
          decimalSeparator=":"
          format="D:H:m:s"
          onFinish={() => refresh()}
          title={text}
          value={campaignStartDate as unknown as string}
         /> */}
      </>;
  }
  return <></>;
}
export default CountDown;