export async function fetcher<JSON = unknown>(
  input: RequestInfo,
  init?: RequestInit,
): Promise<JSON> {
  const res = await fetch(input, {
    headers: {
      accept: "application/json",
      "content-type": "application/json",
    },
    ...init,
  });

  // if (!res.ok) {
  //   const error = new Error("An error occurred while fetching the data.");
  //   // Attach extra info to the error object.
  //   // error.info = await res.json();
  //   // error.status = res.status;
  //   throw error;
  // }

  return res.json();
}

export async function handleRequest<ARG, RESPONSE>(
  url: string,
  { arg }: { arg: ARG },
): Promise<RESPONSE> {
  return await fetcher(url, {
    body: JSON.stringify({ ...arg }),
    method: "POST",
  });
}

export async function handleDelete<ARG, RESPONSE>(
  url: string,
  { arg }: { arg: ARG },
): Promise<RESPONSE> {
  return await fetcher(url, {
    body: JSON.stringify({ ...arg }),
    method: "DELETE",
  });
}
