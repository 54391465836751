import i18nConfig from "@/i18nConfig";
import { createInstance, i18n, Resource } from "i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next/initReactI18next";

export default async function initTranslations(
  locale: string,
  namespaces: string[],
  i18nInstance?: i18n,
  resources?: Resource,
) {
  i18nInstance = i18nInstance || createInstance();

  i18nInstance.use(initReactI18next);

  if (!resources) {
    i18nInstance.use(
      resourcesToBackend(
        (language: string, namespace: string) =>
          import(`@/locales/${language}/${namespace}.json`),
      ),
    );
  }

  await i18nInstance.init({
    defaultNS: namespaces[0],
    fallbackLng: i18nConfig.defaultLocale,
    fallbackNS: namespaces[0],
    lng: locale,
    ns: namespaces,
    preload: resources ? [] : i18nConfig.locales,
    react: {
      transEmptyNodeValue: "",
      transKeepBasicHtmlNodesFor: ["a"],
      transSupportBasicHtmlNodes: true,
      transWrapTextNodes: "",
    },
    resources,
    supportedLngs: i18nConfig.locales,
  });

  return {
    i18n: i18nInstance,
    resources: i18nInstance.services.resourceStore.data,
    t: i18nInstance.t,
  };
}
