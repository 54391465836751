import { API_KEY, endpoints } from "./constant";
import {
  CreateTradeInOrderResponse,
  GetDiagnosticQuotedPriceResponse,
  GetDiagnosticResponse,
  GetEligibleVoucherRequest,
  GetEligibleVoucherResponse,
  GetModelsResponse,
  GetProductBrandsResponse,
  GetProductCategoryResponse,
  GetProductsResponse,
  GetVariantsResponse,
  TradeInOrder,
  ValidateIMEIResponse,
} from "./type";

export const headers = {
  accept: "application/json",
  "content-type": "application/json",
  "X-API-KEY": API_KEY,
};

export const services = {
  createTradeInOrder: async (request: TradeInOrder) => {
    const response = await fetch(endpoints.createOrder, {
      body: JSON.stringify(request),
      headers,
      method: "POST",
    });

    if (!response.ok) {
      const error = await response.json();

      throw new Error(
        error?.message || "An error occurred while creating trade-in order.",
      );
    }

    const data: CreateTradeInOrderResponse = await response.json();
    return data;
  },
  getDiagnosticQuestions: async (productVariantId: string) => {
    const response = await fetch(
      `${endpoints.getDiagnosticQuestions}/?productVariantId=${productVariantId}`,
      {
        headers,
        method: "GET",
      },
    );

    if (!response.ok) {
      const error = await response.json();

      throw new Error(
        error?.message ||
          "An error occurred while fetching diagnostic questions.",
      );
    }

    const data: GetDiagnosticResponse = await response.json();

    return data;
  },
  getDiagnosticQuotedPrice: async (request: {
    diagnosticResult: Record<string, string>;
    productVariantId: string;
  }) => {
    const response = await fetch(endpoints.getDiagnosticQuotedPrice, {
      body: JSON.stringify(request),
      headers,
      method: "POST",
    });

    if (!response.ok) {
      const error = await response.json();

      throw new Error(
        error?.message || "An error occurred while fetching quoted price.",
      );
    }

    const data: GetDiagnosticQuotedPriceResponse = await response.json();
    return data;
  },
  getEligibleVoucher: async (request: GetEligibleVoucherRequest) => {
    const response = await fetch(
      `${endpoints.getEligibleVoucher}/?deviceDiagnosticUuid=${request.deviceDiagnosticUuid}&tradeUpId=${request.tradeUpId}`,
      {
        headers,
        method: "GET",
      },
    );

    if (!response.ok) {
      const error = await response.json();

      throw new Error(
        error?.message || "An error occurred while fetching eligible voucher.",
      );
    }

    const data: GetEligibleVoucherResponse = await response.json();
    return data;
  },
  getProductBrands: async (productCategoryId: string) => {
    const response = await fetch(
      `${endpoints.getProductBrands}/?productCategoryId=${productCategoryId}`,
      {
        headers,
        method: "GET",
      },
    );

    if (!response.ok) {
      const error = await response.json();

      throw new Error(
        error?.message || "An error occurred while fetching product brands.",
      );
    }

    const data: GetProductBrandsResponse = await response.json();

    return data;
  },
  getProductCategories: async () => {
    const response = await fetch(endpoints.getProductCategories, {
      headers,
      method: "GET",
    });

    if (!response.ok) {
      const error = await response.json();

      throw new Error(
        error?.message ||
          "An error occurred while fetching product categories.",
      );
    }

    const data: GetProductCategoryResponse = await response.json();

    return data;
  },
  getProductModels: async (productGroupId: string) => {
    const response = await fetch(
      `${endpoints.getProductModels}/?productGroupId=${productGroupId}`,
      {
        headers,
        method: "GET",
      },
    );

    if (!response.ok) {
      const error = await response.json();

      throw new Error(
        error?.message || "An error occurred while fetching product models.",
      );
    }

    const data: GetModelsResponse = await response.json();

    return data;
  },
  getProducts: async () => {
    const response = await fetch(endpoints.getProducts, {
      headers,
      method: "GET",
    });

    if (!response.ok) {
      const error = await response.json();

      throw new Error(
        error?.message ||
          "An error occurred while fetching product categories.",
      );
    }

    const data: GetProductsResponse = await response.json();

    return data;
  },
  getProductVariants: async (productId: string) => {
    const response = await fetch(
      `${endpoints.getProductVariants}/?productId=${productId}`,
      {
        headers,
        method: "GET",
      },
    );

    if (!response.ok) {
      const error = await response.json();

      throw new Error(
        error?.message || "An error occurred while fetching product variants.",
      );
    }

    const data: GetVariantsResponse = await response.json();

    return data;
  },
  validateIMEI: async (
    imeiNumber: string,
    productVariantId: string,
  ): Promise<ValidateIMEIResponse> => {
    const response = await fetch(
      `${endpoints.validateIMEI}/${imeiNumber}/validate/productVariantId/${productVariantId}`,
      {
        headers,
        method: "GET",
      },
    );

    if (!response.ok) {
      const error = await response.json();

      if (error?.statusCode) {
        throw new Error(
          error?.message?.EN || "Something went wrong. Please try again.",
        );
      }

      throw new Error(
        error?.message || "Something went wrong. Please try again.",
      );
    }

    const data: ValidateIMEIResponse = await response.json();
    return data;
  },
  validatePostcode: async (postcode: string) => {
    const response = await fetch(
      `${endpoints.validatePostcode}/${postcode}/validate`,
      {
        headers,
        method: "GET",
      },
    );

    if (!response.ok) {
      const error = await response.json();

      throw new Error(
        error?.message || "An error occurred while validating postcode.",
      );
    }

    const data: ValidateIMEIResponse = await response.json();
    return data;
  },
};
