import { endpoints } from "@/services/endpoints";
import axios, { AxiosError } from "axios";
import useSWR from "swr";

import { Configuration } from "../type";

const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_WORDPRESS_URL,
});

const fetcher = (url: string) => instance.get(url).then((res) => res.data);

export function useConfiguration() {
  return useSWR<Configuration, AxiosError>(endpoints.CONFIG, fetcher);
}

export default useConfiguration;
