import { AddItemToCartMutationVariables } from "@/__generated__/graphql";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import {
  Brand,
  DiagnosticQuoted,
  Model,
  ProductCategory,
  TradeInOrder,
  Variant,
  Voucher,
} from "../libs/type";
import { ProductDiagnosticFormState } from "../ProductDiagnosticForm";
import { SelectDeliverFormState } from "../SelectDeliverForm";
import { SelectDeviceFormState } from "../SelectDeviceForm";

type Action = "added" | "cancel" | "create" | "update";

type FormState = {
  productDiagnostic?: ProductDiagnosticFormState;
  selectDeliver?: SelectDeliverFormState;
  selectedDevice?: SelectDeviceFormState;
};

export type TradeInState = {
  action: Action;
  cartKey?: string;
  diagnosticQuoted: DiagnosticQuoted | null;
  formValues: FormState;
  isDone: boolean;
  isJoining: "no" | "yes" | null;
  isModalOpen: boolean;
  selectedBrand: Brand | null;
  selectedCategory: null | ProductCategory;
  selectedModel: Model | null;
  selectedVariant: null | Variant;
  step: number;
  tradeInOrder: null | TradeInOrder;
  tradeUpId?: null | number;
  voucher: null | Voucher;
};

type TradeInAction = {
  closeModal: () => void;
  openModal: () => void;
  reset: () => void;
  setAction: (action: Action) => void;
  setCartKey: (cartKey: string) => void;
  setCategory: (category: ProductCategory) => void;
  setDiagnosticQuoted: (price: DiagnosticQuoted) => void;
  setDone: (isDone: boolean) => void;
  setFormValues: (values: FormState) => void;
  setIsJoining: (isJoining: "no" | "yes" | null) => void;
  setSelectedBrand: (brand: Brand) => void;
  setSelectedModel: (model: Model) => void;
  setSelectedVariant: (variant: Variant) => void;
  setStep: (step: number) => void;
  setTradeInOrder: (order: TradeInOrder) => void;
  setTradeUpId: (tradeUpId: number) => void;
  setVoucher: (voucher: Voucher) => void;
};

const initialState: TradeInState = {
  action: "create",
  diagnosticQuoted: null,
  formValues: {
    productDiagnostic: {},
    selectDeliver: {
      acceptTermsAndConditions: false,
      bankAccountName: "",
      bankAccountNo: "",
      bankId: "",
      customerEmail: "",
      customerEmailConfirm: "",
      customerMobilePhone: "",
      customerName: "",
      dropoffStoreId: "",
      imeiSerialNo: "",
      pickupDropoffType: "pickup",
    },
    selectedDevice: {
      band: undefined,
      category: undefined,
      model: undefined,
      variant: undefined,
    },
  },
  isDone: false,
  isJoining: null,
  isModalOpen: false,
  selectedBrand: null,
  selectedCategory: null,
  selectedModel: null,
  selectedVariant: null,
  step: 0,
  tradeInOrder: null,
  tradeUpId: 1566, // TODO: remove this
  voucher: {
    amount: 0,
    voucherAmountType: "",
    voucherCode: "",
    voucherId: null,
    voucherName: "",
    voucherType: "",
  },
};

export const useTradeInStore = create(
  persist<TradeInAction & TradeInState>(
    (set, get) => ({
      ...initialState,
      closeModal: () => set((state) => ({ ...state, isModalOpen: false })),
      openModal: () => set((state) => ({ ...state, isModalOpen: true })),
      reset: () =>
        set(() => {
          return {
            ...initialState,
            action: get().action,
          };
        }),
      setAction: (action) => set((state) => ({ ...state, action: action })),
      setCartKey: (cartKey) => set((state) => ({ ...state, cartKey: cartKey })),
      setCategory: (category) =>
        set((state) => ({ ...state, selectedCategory: category })),
      setDiagnosticQuoted: (data) =>
        set((state) => ({ ...state, diagnosticQuoted: data })),
      setDone: (isDone) => set((state) => ({ ...state, isDone: isDone })),
      setFormValues: (values) =>
        set((state) => ({ ...state, formValues: values })),
      setIsJoining: (isJoining) =>
        set((state) => ({ ...state, isJoining: isJoining })),
      setSelectedBrand: (brand) =>
        set((state) => ({ ...state, selectedBrand: brand })),
      setSelectedModel: (model) =>
        set((state) => ({ ...state, selectedModel: model })),
      setSelectedVariant: (variant) =>
        set((state) => ({ ...state, selectedVariant: variant })),
      setStep: (step) => set((state) => ({ ...state, step: step })),
      setTradeInOrder: (order) =>
        set((state) => ({ ...state, tradeInOrder: order })),
      setTradeUpId: (tradeUpId) =>
        set((state) => ({ ...state, tradeUpId: tradeUpId })),
      setVoucher: (voucher) => set((state) => ({ ...state, voucher: voucher })),
    }),
    {
      name: "tradeInSession",
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);

export default useTradeInStore;
